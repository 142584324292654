const state = {
  
}
export default {
  recGoodsSearch:{},//多商户页面搜索数据
  oldPath:'',
  loadingColor:'rgba(0,0,0,0.5)',
	loadingText: "拼命加载中",
  // xiaoQuName:''
}
