export default{
  setToken(state,tokenValue){
    state.token = tokenValue
  },
  setTime(state,tokenValue){
    state.time = tokenValue
  },
  //修改左侧菜单
  setNavIndex(state,tokenValue){
    state.navIndex = tokenValue
  },
  //客户服务消息数量
  setMsgNum(state,msgNumValue){
    state.msgNum = msgNumValue
  },
  //多商户页面搜索数据
  setRecGoodsSearch(state,v){
  	for (const key in v) {
  		state.recGoodsSearch[key] = v[key];
  	}
  },
  //设置前一个页面的路径
  setOldPath(state,v){
  	state.oldPath=v;
  },
  //设置小区名字
  // setXiaoQuName(state,v){
  //   state.xiaoQuName = v
  // }
}