import Vue from 'vue'
import App from './App.vue'
import router from './router'


//引入element ui 组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

//引入清除样式
import './style/reset.css'
//引入全局样式
import './style/global.css'
import './style/common.css'

import store from '@/store/index.js'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
