import Vue from 'vue'
import Router from 'vue-router'
//

//避免当前位置的重复导航
//避免左侧menu重复点击的时候，路由重复导航
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

 const routes=[
    //登录页面
    {
      path: '/',
      name: 'LoginOut',
      redirect:'/login',
      component: () => import('@/pages/login/loginOut.vue'),
      children:[
        //登录
        {
          path: '/login',
          name: 'Login',
          component: () => import('@/pages/login/login.vue'),
        },
      ]
    },

    //主页面
    {
      path:'/home',
      name:'Home',
      component:() => import('@/pages/home.vue'),
      redirect:'/editXiaoQu',
      children:[
        //小区管理
        // {
        //   path: '/xiaoQuList',
        //   component: () => import('@/pages/house/xiaoQuManager/xiaoQuList.vue')
        // },
        {
          path: '/editXiaoQu',
          component: () => import('@/pages/house/xiaoQuManager/editXiaoQu.vue')
        },
        //楼栋管理
        {
          path:'/louDongList',
          component: () => import('@/pages/house/louDongManger/louDongList.vue')
        },
        {
          path:'/addLouDong',
          component: () => import('@/pages/house/louDongManger/addLouDong.vue')
        },
        {
          path:'/editLouDong',
          component: () => import('@/pages/house/louDongManger/editLouDong.vue')
        },
        //住房管理
        {
          path:'/zhuFangList',
          component: () => import('@/pages/house/zhuFangManager/zhuFangList.vue')
        },
        {
          path:'/addZhuFang',
          component: () => import('@/pages/house/zhuFangManager/addZhuFang.vue')
        },
        {
          path:'/editZhuFang',
          component: () => import('@/pages/house/zhuFangManager/editZhuFang.vue')
        },
        {
          path:'/renYuanListInZhuFang',
          component: () => import('@/pages/house/zhuFangManager/renyuan.vue')
        },
        //住户管理
        {
          path:'/zhuHuList',
          component: () => import('@/pages/house/zhuHuManger/zhuHuList.vue')
        },
        {
          path:'/addZhuHu',
          component: () => import('@/pages/house/zhuHuManger/addZhuHu.vue')
        },
        {
          path:'/editZhuHu',
          component: () => import('@/pages/house/zhuHuManger/editZhuHu.vue')
        },
        //审核列表
        {
          path:'/shenHeList',
          component: () => import('@/pages/house/shenHeList/shenHeList.vue')
        },
        //部门列表
        {
          path:'/departMentList',
          component: () => import('@/pages/department/departmentManager/departmentList.vue')
        },
        {
          path:'/addDepartment',
          component: () => import('@/pages/department/departmentManager/addDepartment.vue')
        },
        //岗位列表
        {
          path:'/postList',
          component: () => import('@/pages/department/post/postList.vue')
        },
        {
          path:'/addPost',
          component: () => import('@/pages/department/post/addPost.vue')
        },
        //员工列表
        {
          path:'/staffList',
          component: () => import('@/pages/department/staff/staffList.vue')
        },
        {
          path:'/addStaff',
          component: () => import('@/pages/department/staff/addStaff.vue')
        },
        //离职列表
        {
          path:'/quitList',
          component: () => import('@/pages/department/quit/quitList.vue')
        },
        //修改密码
        {
          path:'/changePwd',
          component: () => import('@/pages/house/changePwd.vue')
        },
		//生成二维码
		{
		  path:'/parking',
		  component: () => import('@/pages/parking/parking.vue')
		},
		//二维码列表
		{
		  path:'/parkingList',
		  component: () => import('@/pages/parking/parkingList.vue')
		},
		//缴费列表
		{
		  path:'/cashRecord',
		  component: () => import('@/pages/parking/cashRecord.vue')
		},
      ]
    },
  ]

const router = new Router({
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   router.app.$options.store.commit('setOldPath',from.path);
//   // console.log(router.app.$options);
//   // console.log(router.app.$options.store.state.navIndex);
//   // router.app.$options.store._mutations.setNavIndex()
//   next();
// })
export default router
