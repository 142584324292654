
const  getters = {
  name:function(state){
    return state.name
  },
  token:function(state){
    return state.token
  },
  time:function(state){
    return state.time
  },
  navIndex:function(state){
    return state.navIndex
  },
  msgNum:function(state){
    return state.msgNum
  },
  // xiaoQuName:function(state){
  //   return state.xiaoQuName
  // }
}
export default getters